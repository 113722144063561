import { ModeToggle } from "@/components/mode-toggle";
import { Logo } from "@/components/logo";

function Navbar() {
    return (
        <nav className="bg-transparent dark:bg-transparent rounded-lg">
            <div className="mx-auto max-w-7xl px-0 sm:px-0 lg:px-0">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="justify-center sm:items-stretch sm:justify-start">
                        <div className="flex flex-shrink-0 items-center ">
                            <Logo />
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <ModeToggle />
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
