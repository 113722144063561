import { useEffect, useState } from "react";
import { ThemeProvider } from "@/components/theme-provider";
import { BetaCard } from "@/components/beta-card";
import "./App.css";
import Navbar from "./Navbar";

interface BetaProgramConfig {
  id: string;
  text: string;
  title: string;
  tags: string[];
  links: BetaLink[];
  joined: boolean;
}

interface BetaLink {
    text: string;
    url: string;
}

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<BetaProgramConfig[]>([]);
  const [programStates, setProgramStates] = useState(
    new Map<string, boolean>()
  );

  useEffect(() => {
    const fetchData = (iteration: number) => {
      fetch("config")
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          setConfig(data as BetaProgramConfig[]);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          if (iteration < 5) {
            setTimeout(() => fetchData(iteration + 1), 1000);
          } else {
            setLoading(false);
          }
        });
    };

    fetchData(1);
  }, [setConfig, setLoading]);

  useEffect(() => {
    const newMap = new Map<string, boolean>();
    config.forEach((c) => {
      newMap.set(c.id, c.joined);
    });
    setProgramStates(newMap);
  }, [config, setProgramStates]);

  const contents = loading ? (
    <p>Vennligst vent, henter tilgjengelige prøvefunksjoner... </p>
  ) : (
    <div>
      {config.map((c) => (
        <BetaCard
          key={c.id}
          badges={c.tags}
          links={c.links}
          id={c.id}
          isActive={checkEnabled(c.id)}
          join={() => {
            join(c.id).catch(console.error);
          }}
          leave={() => {
            leave(c.id).catch(console.error);
          }}
          text={c.text}
          title={c.title}
        />
      ))}
    </div>
  );

  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <Navbar />

        <h1 className="font-sans text-2xl font-semibold leading-none tracking-tight space-y-4 py-8 text-align-center">
          Prøv ut nye funksjoner?
        </h1>
        {config && contents}
        {!config && <h3>Henter tilgjengelige funksjoner...</h3>}
      </div>
      <p className="p-8 text-sm">
        <span className="italic">
          Her kommer det gjerne flere ting å prøve ut etterhvert
        </span>
        <span className="mx-2">🍪</span>
      </p>
    </ThemeProvider>
  );

  function checkEnabled(id: string): boolean {
    const isEnabled = programStates.get(id);
    if (isEnabled === true) {
      return true;
    }
    return false;
  }

  async function join(id: string) {
    await fetch(`betaprogram/join?id=${id}`);
    setProgramStates(new Map(programStates.set(id, true)));
  }

  async function leave(id: string) {
    await fetch(`betaprogram/leave?id=${id}`);
    setProgramStates(new Map(programStates.set(id, false)));
  }
}

export default App;
