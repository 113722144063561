﻿"use client";

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import {Label} from "@/components/ui/label";
import {Switch} from "@/components/ui/switch";
import {Badge} from "@/components/ui/badge";
import {Separator} from "@/components/ui/separator"
import {buttonVariants} from "@/components/ui/button"

interface BetaCardProps {
    isActive: boolean;
    join: () => void;
    leave: () => void;
    title: string;
    text: string;
    badges: string[];
    links: BetaLink[];
    id: string;
}

interface BetaLink {
    text: string;
    url: string;
}

export function BetaCard({isActive, join, leave, title, text, badges, links, id}: BetaCardProps) {

    return (
        <div className="grid justify-items-center hover:justify-items-center w-full">
            <Card
                className="mx-auto w-full mt-4 drop-shadow-md dark:drop-shadow-glow2 hover:dark:drop-shadow-glow transition">
                <CardHeader>
                    <CardTitle>
                        <span className="mr-4">{title}</span>
                        <Badge variant="custom2">{badges}</Badge>
                    </CardTitle>
                    <CardDescription className="pt-4 text-lg text-foreground/90">
                        {text}
                    </CardDescription>
                </CardHeader>
                <CardContent className="grid gap-6">
                    <div className="flex items-left justify-between space-x-2">
                        <Label htmlFor={id} className="flex flex-col space-y-1 text-base">
                            <span>
                                {title} er{" "}
                                {isActive ? (
                                    <span className="text-green-600">aktivert</span>
                                ) : (
                                    <span className="">ikke aktivert</span>
                                )}
                            </span>

                        </Label>
                        <Switch
                            id={id}
                            name={title}
                            checked={isActive}
                            onCheckedChange={(enabled: boolean) => {
                                enabled ? join() : leave();
                            }}
                        />

                    </div>
                    <Separator/>
                    <ul className="flex flex-wrap text-gray-900 dark:text-white">
                        {links.map((link: BetaLink) => (
                            <li key={link.url} className="pr-4">
                                <a
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={buttonVariants({variant: "outline"})}
                                >
                                    {link.text}
                                </a>
                            </li>
                        ))}
                    </ul>
                </CardContent>
            </Card>
        </div>
    );
}
